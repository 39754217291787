import React from 'react';
import Demo from '../pages/Demo';
import Dashboard from '../pages/Dashboard';
import TransCreate from '../pages/TransCreate/TransCreate';
import TransUpdateForming from '../pages/TransUpdate/TransUpdateForming';
import TransUpdateAligning from '../pages/TransUpdate/TransUpdateAligning';
import TransUpdateDrilling from '../pages/TransUpdate/TransUpdateDrilling';
import TransUpdateSintering from '../pages/TransUpdate/TransUpdateSintering';
import TransUpdateGrinding from '../pages/TransUpdate/TransUpdateGrinding';
import PackageCreate from '../pages/PackageCreate';
import PackageSeparation from '../pages/PackageSeparation';
import TransUpdateTesting from '../pages/TransUpdate/TransUpdateTesting';
import TransUpdatePackaging from '../pages/TransUpdate/TransUpdatePackaging';
import TransUpdateSuccess from '../pages/TransUpdate/TransUpdateSuccess';
import TransUpdateFailed from '../pages/TransUpdate/TransUpdateFailed';
import FormingLossDeclare from '../pages/FormingLossDeclare/FormingLossDeclare';
import QCForming from '../pages/QCs/QCForming/QCForming';
import SettingsPrivilege from '../pages/SettingsPrivilege/SettingsPrivilege';
import SettingsConfig from '../pages/SettingsConfig/SettingsConfig';
import TransExport from '../pages/TransExport/TransExport';
import QCNGHandle from '../pages/QCs/QCNGHandle/QCNGHandle';
import QCSinteringContainer from '../pages/QCs/QCSintering/QCSinteringContainer';
import { qcFormingPath, sinteringSizePath } from '../constants/enums';
import QCExport from '../pages/QCs/QCExport/QCExport';
import QCReport from '../pages/Reports/QCReport/QCReport';
import TransReport from '../pages/Reports/TransReport/TransReport';
import FormingStock from '../pages/FormingStock/FormingStock';
import QCSinteringElectricalPage from '../pages/QCs/QCSintering/QCSinteringElectrical/QCSinteringElectricalPage';

const routes = [
  {
    name: 'demo',
    displayName: 'Demo page',
    path: '/demo',
    component: <Demo />,
  },
  {
    name: 'home',
    displayName: 'Home',
    path: '/',
    component: <Dashboard />,
  },
  {
    name: 'trans-create',
    displayName: '建立移轉單',
    path: '/trans-create',
    component: <TransCreate />,
  },
  {
    name: 'trans-update-forming',
    displayName: '更新移轉單（壓型）',
    path: '/trans-update-forming/:trans_id',
    component: <TransUpdateForming />,
  },
  {
    name: 'trans-update-forming',
    displayName: '更新移轉單（鑽孔）',
    path: '/trans-update-drilling/:trans_id',
    component: <TransUpdateDrilling />,
  },
  {
    name: 'trans-update-aligning',
    displayName: '更新移轉單（排列）',
    path: '/trans-update-aligning/:trans_id',
    component: <TransUpdateAligning />,
  },
  {
    name: 'trans-update-sintering',
    displayName: '更新移轉單（燒結）',
    path: '/trans-update-sintering/:trans_id',
    component: <TransUpdateSintering />,
  },
  {
    name: 'trans-update-grinding',
    displayName: '更新移轉單（研磨）',
    path: '/trans-update-grinding/:trans_id',
    component: <TransUpdateGrinding />,
  },
  {
    name: 'package-create',
    displayName: '建立檢裝單',
    path: '/package-create',
    component: <PackageCreate />,
  },
  {
    name: 'package-separation',
    displayName: '拆批檢裝單',
    path: '/package-separation',
    component: <PackageSeparation />,
  },
  {
    name: 'package-update-testing',
    displayName: '更新檢裝單（檢測）',
    path: '/package-update-testing/:trans_id',
    component: <TransUpdateTesting />,
  },
  {
    name: 'package-update-packaging',
    displayName: '更新檢裝單（包裝）',
    path: '/package-update-packaging/:trans_id',
    component: <TransUpdatePackaging />,
  },
  {
    name: 'trans-update-success',
    displayName: '更新移轉單成功',
    path: '/trans-update-success',
    component: <TransUpdateSuccess />,
  },
  {
    name: 'trans-update-failed',
    displayName: '更新移轉單失敗',
    path: '/trans-update-failed',
    component: <TransUpdateFailed />,
  },
  {
    name: 'forming-loss-declare',
    displayName: '鑽孔排列損耗申報',
    path: '/forming-loss-declare',
    component: <FormingLossDeclare />,
  },
  {
    name: 'forming-stock',
    displayName: '鑽孔排列盤點',
    path: '/forming-stock',
    component: <FormingStock />,
  },
  {
    name: 'trans-export',
    displayName: '匯出移轉單',
    path: '/trans-export',
    component: <TransExport />,
  },
  {
    name: 'trans-report',
    displayName: '移轉單 Report',
    path: '/trans-report',
    component: <TransReport />,
  },
  {
    name: 'trans-report-scOd_progress',
    displayName: '移轉單 Report',
    path: '/trans-report/:transScOdNo',
    component: <TransReport isFromDeliveryProgress />,
  },
  {
    name: 'qc-forming',
    displayName: '壓型檢測',
    path: '/qc-forming',
    component: <QCForming path={qcFormingPath.FORMING} />,
  },
  {
    name: 'qc-sintering-inspection',
    displayName: '燒結巡檢',
    path: '/qc-sintering-inspection',
    component: <QCSinteringContainer path={sinteringSizePath.INSPECTION} />,
  },
  {
    name: 'qc-sintering-size',
    displayName: '燒結尺寸檢測',
    path: '/qc-sintering-size',
    component: <QCSinteringContainer path={sinteringSizePath.SIZE} />,
  },
  {
    name: 'qc-sintering-electrical',
    displayName: '燒結電性檢測',
    path: '/qc-sintering-electrical',
    component: <QCSinteringElectricalPage />,
    // component: <QCSinteringContainer path={sinteringSizePath.ELECTRICAL} />,
  },
  {
    name: 'qc-sintering-ng',
    displayName: 'NG處理',
    path: '/qc-sintering-ng',
    component: <QCNGHandle />,
  },
  {
    name: 'qc-export',
    displayName: 'Export',
    path: '/qc-export',
    component: <QCExport />,
  },
  {
    name: 'qc-report',
    displayName: 'QC Report',
    path: '/qc-report',
    component: <QCReport />,
  },
  {
    name: 'settings-privilege',
    displayName: '權限管理',
    path: '/settings-privilege',
    component: <SettingsPrivilege />,
  },
  {
    name: 'settings-config',
    displayName: '設定管理',
    path: '/settings-config',
    component: <SettingsConfig />,
  },
];

export default routes;
