/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef, useEffect } from 'react';
import { format } from 'date-fns';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import useStyles from './useStyles';
import useCommonStyles from '../useStyles';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import SelectField from '../../../components/SelectField/SelectField';
import InputField from '../../../components/InputField/InputField';
import Button from '../../../components/Button/Button';
import Snackbar from '../../../components/Snackbar/Snackbar';
import TransInfo from './TransInfo';
import regex from '../../../constants/validationRegex';
import useAxios from '../../../hooks/useAxios';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import { QCNGActions } from '../../../constants/enums';
import useCancelToken from '../../../hooks/useCancelToken';

const QCNGHandle = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const [transId, setTransId] = useState('');
  const [showConfig, setShowConfig] = useState(false);
  const [transIdErr, setTransIdErr] = useState(false);
  const [transNGRecords, setTransNGRecords] = useState([]);
  const [NGActionSelection, setNGActionSelection] = useState([]);
  const [isValidNGTrans, setIsValidNGTrans] = useState(false);
  const [NGAction, setNGAction] = useState('0');
  const [NGActionErr, setNGActionErr] = useState(false);
  const [NGNote, setNGNote] = useState('');
  const [mergedTransIds, setMergedTransIds] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const successTimer = useRef(null);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();

  const resetAllFields = () => {
    setTransId('');
    setTransIdErr(false);
    setTransNGRecords([]);
    setNGAction('0');
    setNGActionErr(false);
    setNGNote('');
    setMergedTransIds('');
  };

  const handleTransIdChange = (e) => {
    if (transIdErr) {
      setTransIdErr(false);
    }
    localStorage.setItem('NGTransId', '');
    const { value } = e.target;
    setTransId(value);
    setIsValidNGTrans(false);
    setTransNGRecords([]);
  };

  const handleNGActionChange = (e) => {
    if (NGActionErr) {
      setNGActionErr(false);
    }
    setNGAction(e.target.value);
  };

  const handleNGNoteChange = (e) => {
    setNGNote(e.target.value);
  };

  const validationTransId = () => {
    if (transId.length === 0) {
      setTransIdErr(true);
      return false;
    }
    if (!regex.FORMING_TRANS_ID.test(transId) && !regex.SINTERING_TRANS_ID.test(transId)) {
      setTransIdErr(true);
      return false;
    }
    return true;
  };

  const openSuccessSnackBar = (msg) => {
    setSuccessMsg(msg);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    clearTimeout(successTimer.current);
    successTimer.current = setTimeout(() => {
      setSuccessMsg('');
      clearTimeout(successTimer.current);
    }, 3000);
  };

  const handleFetch = async () => {
    if (!validationTransId()) return;
    const cancelToken = newCancelToken();
    const [status, result, _, noPrivilegeMsg] = await useAxios(
      '/getNgList',
      { transId },
      true,
      cancelToken
    );
    if (status) {
      const { action, history, ngCount, mergedTransId } = result;
      if (history.length === 0 && ngCount === 0) {
        setIsValidNGTrans(false);
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '無法進行NG處理',
            message: '此移轉單號沒有NG的檢測紀錄，請輸入其他移轉單號，或檢查此移轉單的檢測紀錄！',
          },
        });
        return;
      }
      setIsValidNGTrans(true);
      setMergedTransIds(mergedTransId ?? '');
      setNGActionSelection(
        action.map((item) => ({
          name: item.ngName,
          value: item.ngId,
        }))
      );
      setTransNGRecords(
        history
          .filter((record) => record.ngId && record.ngId !== 0)
          .map((record, idx) => ({
            id: idx + 1,
            listId: record.listId,
            action: record.ngName,
            updateDateTime: format(new Date(record.createDate), 'yyyy-MM-dd HH:mm:ss'),
            note: record.ngComment,
          }))
      );
      // 多筆ng -> 抓最後一筆做判定
      if (history.length === 0) {
        setShowConfig(true);
        return;
      }
      const lastNGRecord = history[history.length - 1];
      if (
        lastNGRecord.ngId === QCNGActions.FORMING_PASS || // 廠長判定ok
        lastNGRecord.ngId === QCNGActions.RECYCLE || // 半品回收
        lastNGRecord.ngId === QCNGActions.RETIRED || // 報廢
        lastNGRecord.ngId === QCNGActions.SINTERING_PASS // 廠長判定ok
      ) {
        setShowConfig(false);
        return;
      }
      setShowConfig(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得移轉單資訊',
          message: noPrivilegeMsg || '目前暫時無法取得移轉單資訊，請稍後再試，或請聯絡網管人員！',
        },
      });
    }
  };

  const validationNGAction = () => {
    if (NGAction === '0') {
      setNGActionErr(true);
      return false;
    }
    return true;
  };

  const handleConfirm = async () => {
    if (!validationNGAction()) return;
    const data = {
      ngId: NGAction,
      ngComment: NGNote,
    };

    if (mergedTransIds !== '') {
      // 有合併過，傳入合併單號
      data.mergedTransIds = mergedTransIds;
    } else {
      // 沒有合併過，傳入 transId
      data.transId = transId;
    }

    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/ngSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      setShowConfig(false);
      openSuccessSnackBar('新增NG處理紀錄成功！');
      resetAllFields();
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法儲存NG處理',
          message: noPrivilegeMsg || '目前無法儲存此筆NG處理紀錄，請稍後再試，或請通知網管人員！',
        },
      });
    }
  };

  useEffect(
    () => () => {
      clearTimeout(successTimer.current);
    },
    []
  );

  useEffect(() => {
    const ngItem = localStorage.getItem('NGTransId');
    if (ngItem && ngItem !== '') {
      setTransId(ngItem);
      if (transId.length !== 0) handleFetch();
    }
  }, [transId]);
  useEffect(() => {
    window.onbeforeunload = () => localStorage.setItem('NGTransId', '');

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <div className={commonClasses.main}>
      <PageTitle title="NG處理" />
      <TransInfo
        transId={transId}
        transIdErr={transIdErr}
        mergedTransIds={mergedTransIds}
        transNGRecords={transNGRecords}
        handleFetch={handleFetch}
        handleTransIdChange={handleTransIdChange}
        isValidNGTrans={isValidNGTrans}
      />
      <Snackbar
        msg={successMsg}
        open={successMsg.length > 0}
        type="success"
        className={classes.success_snackbar}
      />
      {showConfig && (
        <PaperContainer className={`${commonClasses.container} ${classes.config_container}`}>
          <Typography variant="h6">NG後續處理動作</Typography>
          <Grid container className={commonClasses.content}>
            <Grid item xs={5} className={commonClasses.field_item}>
              <SelectField
                id="NGAction"
                label="處理動作"
                placeholder="請選擇NG處理動作"
                selections={NGActionSelection}
                value={NGAction}
                handleChange={handleNGActionChange}
                error={NGActionErr}
                errormsg="請選擇此次的NG處理動作"
              />
            </Grid>
            <Grid item xs={12} container alignItems="center">
              <InputField
                id="note"
                name="note"
                type="text"
                label="備註"
                placeholder="請輸入備註內容"
                value={NGNote}
                onChange={handleNGNoteChange}
                className={classes.note_input}
              />
              <Button
                variant="contained"
                color="primary"
                rounded
                startIcon={<CheckRoundedIcon />}
                onClick={handleConfirm}
              >
                送出
              </Button>
            </Grid>
          </Grid>
        </PaperContainer>
      )}
    </div>
  );
};

export default QCNGHandle;
