import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '../Dialog/Dialog';
import DialogTitle from '../DialogTitle/DialogTitle';
import DialogContent from '../DialogContent/DialogContent';
import DialogContentText from '../DialogContentText/DialogContentText';

const useStyles = makeStyles()((theme) => ({
  content: {
    '&.MuiDialogContent-root': {
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    },
  },
  button: {
    fontSize: 15,
    fontWeight: theme.typography.body2.fontWeight,
  },
  cancel: {
    color: alpha(theme.palette.common.black, 0.4),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.04),
    },
  },
  confirm: {
    color: '#f50057',
    '&:hover': {
      backgroundColor: alpha('#e57373', 0.1),
    },
  },
}));
const Modal = ({
  title,
  message,
  body,
  cancelText,
  confirmText,
  open,
  handleClose,
  singleBtn,
  noBtn,
  handleConfirm,
  handleCancel,
  disableBackdropClick,
}) => {
  const { classes } = useStyles();
  const onConfirm = () => {
    if (typeof handleConfirm === 'function') {
      handleConfirm();
    }
    handleClose();
  };

  const onCancel = () => {
    if (typeof handleCancel === 'function') {
      handleCancel();
    }
    handleClose();
  };

  const onClose = (_, reason) => {
    if (disableBackdropClick && reason && reason === 'backdropClick') {
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent className={classes.content}>
        {message && <DialogContentText id="alert-dialog-description">{message}</DialogContentText>}
        {body}
      </DialogContent>
      {!noBtn && (
        <DialogActions>
          {!singleBtn && (
            <Button onClick={onCancel} className={`${classes.cancel} ${classes.button}`}>
              {cancelText}
            </Button>
          )}
          <Button onClick={onConfirm} autoFocus className={`${classes.confirm} ${classes.button}`}>
            {confirmText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.defaultProps = {
  title: 'Oops',
  cancelText: '取消',
  confirmText: '確認',
  singleBtn: false,
  noBtn: false,
  handleConfirm: null,
  handleCancel: null,
  body: '',
  message: '',
  disableBackdropClick: false,
};

Modal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  singleBtn: PropTypes.bool,
  noBtn: PropTypes.bool,
  handleConfirm: PropTypes.func,
  body: PropTypes.string,
  handleCancel: PropTypes.func,
  disableBackdropClick: PropTypes.bool,
};

export default Modal;
