/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import useStyles from './useStyles';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';
import useCancelToken from '../../hooks/useCancelToken';
import PageTitle from '../../components/PageTitle/PageTitle';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import TransLabel from '../../components/TransLabel/TransLabel';
import ReadonlyField from '../../components/ReadonlyField/ReadonlyField';
import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import SelectField from '../../components/SelectField/SelectField';
import Divider from '../../components/Divider/Divider';
import regex from '../../constants/validationRegex';

const TransUpdateTesting = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const [transInfo, setTransInfo] = useState({
    transId: '-',
    workId: '-',
    elNo: '-',
    materialName: '-',
    productName: '-',
    createQty: '-',
    transQty: '-',
    ngQty: '-',
  });
  const initialForm = {
    passQty: '',
    defectLoss: '',
    lineNo: '0',
    defectDetail: [],
    time: '',
  };
  const {
    formData: transForm,
    setFormData: setTransForm,
    formErr: transFormErr,
    setFormErr: setTransFormErr,
    resetFormErr,
  } = useForm(initialForm);
  const [targetValue, setTargetValue] = useState(0);
  const [lineNoList, setLineNoList] = useState([]);
  const [defectDetail, setDefectDetail] = useState();
  const [defectTypes, setDefectTypes] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [isCreate, setIsCreate] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [time, setTime] = useState('');
  const [isSeparation, setIsSeparation] = useState(false);
  const ngQtyArr = useRef([]);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { trans_id } = useParams();
  const { newCancelToken } = useCancelToken();

  const getTransData = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getPackage',
      { packageId: trans_id },
      true,
      cancelToken
    );
    if (status) {
      const { detect, pkg } = result;
      const {
        materialName,
        elNo,
        prodName,
        packageId,
        scOdno,
        totalTransQuantity,
        totalCreateQuantity,
        badRate,
        checkPassQuantity,
        lineTypes,
        checkProcessHours,
        feCheckBadEntityList,
      } = pkg;
      setTransInfo({
        elNo,
        materialName,
        transId: packageId,
        workId: scOdno,
        productName: prodName,
        transQty: totalTransQuantity ? totalTransQuantity.toString() : '-',
        createQty: totalCreateQuantity ? totalCreateQuantity.toString() : '-',
      });
      setTargetValue(checkPassQuantity ? totalTransQuantity - checkPassQuantity : '0');
      setTime(checkProcessHours ? checkProcessHours.toString() : '');

      detect.forEach((type) =>
        setDefectDetail((prev) => ({
          ...prev,
          [type.detectId]: '',
        }))
      );
      setDefectTypes(
        detect.map((item) => ({
          name: item.detectId,
          displayName: item.detectName,
        }))
      );
      setTransForm((prev) => ({
        ...prev,
        defectLoss: badRate || '',
        passQty: checkPassQuantity ? checkPassQuantity.toString() : totalTransQuantity,
        lineNo: lineTypes || '0',
        defectDetail: feCheckBadEntityList ? [...feCheckBadEntityList] : [],
      }));
      if (feCheckBadEntityList) {
        feCheckBadEntityList.forEach((type) => {
          setDefectDetail((prev) => ({
            ...prev,
            [type.detectId]: type.detectValue.toString(),
          }));
          ngQtyArr.current = [...ngQtyArr.current, type.detectValue];
          setTransForm((prev) => ({ ...prev, ngQty: ngQtyArr.current.reduce((a, b) => a + b, 0) }));
        });
      }
      if (feCheckBadEntityList || badRate || lineTypes) {
        setIsCreate(false);
      }
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料讀取錯誤',
          message: noPrivilegeMsg || '請掃描有效之移轉單或確定正確掃描移轉單。',
        },
      });
    }
  };

  const handleLineNoChange = (e) => {
    setTransForm((prev) => ({
      ...prev,
      lineNo: e.target.value,
    }));
  };

  const handleDefectDetailChange = useCallback(
    (e) => {
      setTransFormErr((prev) => ({
        ...prev,
        defectDetail: {
          [e.target.name]: false,
        },
      }));
      if (
        (Number(e.target.value) > Number(transForm.passQty) || Number(e.target.value) < 0) &&
        !isSeparation
      ) {
        setTransFormErr((prev) => ({
          ...prev,
          defectDetail: {
            [e.target.name]: true,
          },
        }));
        return;
      }

      let defectDetailTemp = { ...defectDetail, [e.target.name]: e.target.value };
      setDefectDetail((prev) => {
        defectDetailTemp = { ...prev, [e.target.name]: e.target.value };
        return {
          ...prev,
          [e.target.name]: e.target.value,
        };
      });
      if (e.type === 'blur') {
        const sumNGQuantity = Object.values(defectDetailTemp)
          .map((num) => Number(num))
          .reduce((a, b) => a + b, 0);
        const passQty = transInfo.transQty - sumNGQuantity;
        setTransForm((prev) => ({
          ...prev,
          passQty,
          defectLoss: ((passQty / transInfo.transQty) * 100).toFixed(2),
          ngQty: sumNGQuantity,
        }));
        setTargetValue(transInfo.transQty - parseInt(e.target.value, 10));
      }
    },
    [transForm.passQty]
  );

  const validationDefectDetailValue = () => {
    resetFormErr();
    let isValid = true;
    Object.keys(defectDetail).forEach((key) => {
      const value = parseInt(defectDetail[key], 10);
      if (!Number.isNaN(value) && (value > transForm.ngQty || value === 0)) {
        isValid = false;
        setTransFormErr((prev) => ({
          ...prev,
          defectDetail: {
            [key]: true,
          },
        }));
        return false;
      }
      return true;
    });
    return isValid;
  };

  const validationTransForm = () => {
    if (transForm.passQty.length === 0) {
      setErrorMsg('請輸入通過檢測數量');
      setTransFormErr((prev) => ({
        ...prev,
        passQty: true,
      }));
      return false;
    }
    if (transForm.lineNo === '0') {
      setTransFormErr((prev) => ({
        ...prev,
        lineNo: true,
      }));
      return false;
    }
    return true;
  };

  const handleConfirm = async () => {
    const { passQty, defectLoss, lineNo } = transForm;
    const defectDetailArr = Object.keys(defectDetail).map((item) => ({
      detectId: item,
      detectValue: defectDetail[item].length === 0 ? 0 : parseInt(defectDetail[item], 10),
    }));
    const cancelToken = newCancelToken();
    const data = {
      pkg: {
        packageId: transInfo.transId,
        checkPassQuantity: passQty,
        badRate: defectLoss,
        lineTypes: lineNo,
      },
      detect: defectDetailArr,
    };
    if (!isCreate) {
      data.pkg.checkProcessHours = Number(time);
    }
    if (defectLoss < 0) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '錯誤',
          message: `不良品總數量不得大於移轉數量，請重新再輸入一次！`,
        },
      });
      return;
    }
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/updateCheck',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { hasBeenUnbatched, finQuantity } = result;
      setIsSeparation(hasBeenUnbatched);
      if (hasBeenUnbatched) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            singleBtn: true,
            title: '移轉數量已變更',
            message: `移轉數量已變更成【 ${finQuantity} 】，請重新再輸入一次！`,
          },
        });
        setTransInfo((prev) => ({
          ...prev,
          transQty: finQuantity,
        }));
        setTransForm((prev) => ({
          ...prev,
          passQty: finQuantity - transForm.ngQty,
          defectLoss: (((finQuantity - transForm.ngQty) / finQuantity) * 100).toFixed(2),
        }));
        return;
      }
      navigate('/trans-update-success');
    } else {
      if (rtCode === 'E45') {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            singleBtn: true,
            title: '錯誤',
            message: noPrivilegeMsg,
          },
        });
        return;
      }
      navigate('/trans-update-failed');
    }
  };

  const handleTimeChange = (e) => {
    // 當如果刪掉input所有數字就清空
    if (e.target.value === '') {
      setTime('');
    }
    // 若輸入到非數字就 return
    if (!regex.NUMBER.test(e.target.value)) return;
    if (e.target.value.includes('.') && e.target.value.length > e.target.value.indexOf('.') + 2) {
      return;
    }
    setTime(e.target.value);
  };

  const handleSubmit = async () => {
    resetFormErr();
    if (!validationTransForm()) return;
    if (!validationDefectDetailValue()) return;

    handleConfirm();
  };

  useEffect(() => {
    getTransData();
    const lineNoTemp = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    setLineNoList(lineNoTemp.map((item) => ({ name: item, value: item })));
    resetFormErr();
    ngQtyArr.current = [];
  }, []);

  return (
    <Grid container className={classes.root}>
      <PageTitle title="更新檢裝單（檢測）" />
      <PaperContainer>
        <div className={`${classes.content} ${classes.header} ${classes.content_desktop}`}>
          <Typography variant="h6">移轉單資訊</Typography>
          <TransLabel label="檢測" />
        </div>
        <div className={`${classes.content} ${classes.content_desktop}`}>
          <div className={classes.rwd_field}>
            <ReadonlyField
              label="移轉單號 / Trans nomor pesanan"
              value={transInfo.transId}
              name="transId"
            />
          </div>
          <div className={classes.rwd_field}>
            <ReadonlyField
              label="工令單號 / Nomor perintah kerja"
              value={transInfo.workId}
              name="workId"
            />
          </div>
          <div className={classes.rwd_field}>
            <ReadonlyField label="料號 / Bagian No" value={transInfo.elNo} name="elNo" />
          </div>
          <div className={classes.rwd_field}>
            <ReadonlyField
              label="材質 / Bahan"
              value={transInfo.materialName}
              name="materialName"
            />
          </div>
          <div className={classes.rwd_field}>
            <ReadonlyField
              label="產品名稱 / Nama Produk"
              value={transInfo.productName}
              name="productName"
            />
          </div>
          <div className={classes.rwd_field}>
            <ReadonlyField
              label="匣數 / Kuantitas manufaktur"
              value={transInfo.createQty}
              name="createQty"
            />
          </div>
          <div className={classes.rwd_field}>
            <ReadonlyField
              label="移轉數量 / Kuantitus transfer"
              value={transInfo.transQty}
              name="transQty"
            />
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={`${classes.content} ${classes.header} ${classes.content_desktop}`}>
          <Typography variant="h6">製造資訊</Typography>
        </div>
        <div className={classes.rwd_content}>
          <div className={`${classes.rwd_field} ${classes.desktop_full_width}`}>
            <ReadonlyField
              name="passQty"
              label="檢測通過數量 / Jumlah lintasan"
              value={transForm.passQty}
            />
          </div>
          <div className={`${classes.rwd_field} ${classes.defect_field}`}>
            <Typography variant="body1" className={classes.sub_title}>
              不良原因及數量
              <br />
              Alasan dan jumlah cacat
            </Typography>
            {defectTypes.map((type) => (
              <div className={classes.selection} key={type.name}>
                <InputField
                  id={type.name.toString()}
                  label={type.displayName}
                  value={defectDetail[type.name] || ''}
                  placeholder="請輸入數量 / Kuantitas"
                  type="number"
                  name={type.name.toString()}
                  onChange={handleDefectDetailChange}
                  size="small"
                  error={transFormErr.defectDetail[type.name]}
                  errormsg={`請填寫 ${type.displayName} 的正確數量並不得超過未檢測通過之數量`}
                  onBlur={handleDefectDetailChange}
                />
              </div>
            ))}
          </div>
          <div className={`${classes.rwd_field} ${classes.desktop_full_width}`}>
            <ReadonlyField
              name="defectLoss"
              label="確認良率 / Tingkat Hasil"
              value={transForm.defectLoss ? `${transForm.defectLoss}%` : '100%'}
              className={classes.ngTotal}
            />
            <ReadonlyField
              name="ngQty"
              label="不良品總數量"
              value={transForm.ngQty ?? 0}
              className={classes.setMargin}
            />
          </div>
          <div className={`${classes.rwd_field} ${classes.desktop_full_width}`}>
            <SelectField
              id="lineNo"
              label="線別 / Garis produksi"
              placeholder="請選擇線別 / Garis produksi"
              selections={lineNoList}
              value={transForm.lineNo}
              handleChange={handleLineNoChange}
              error={transFormErr.lineNo}
              errormsg="請選擇線別 / Garis produksi！"
            />
          </div>
          {!isCreate && (
            <div className={`${classes.rwd_field} ${classes.desktop_full_width}`}>
              <InputField
                id="time"
                name="time"
                label="總工時 / Jumlah jam kerja"
                placeholder="請輸入總工時 / Jumlah jam kerja"
                value={time}
                onChange={handleTimeChange}
                variant="outlined"
                type="text"
              />
            </div>
          )}
        </div>
        <div className={classes.confirm}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<CheckRoundedIcon />}
            onClick={handleSubmit}
            disabled={isSubmit}
          >
            送出
          </Button>
        </div>
      </PaperContainer>
    </Grid>
  );
};

export default TransUpdateTesting;
