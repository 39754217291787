/* eslint-disable no-unused-vars */
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  sub_transid_container: {
    width: '100%',
    minWidth: 220,
    padding: `${theme.spacing(1)} 0`,
  },
  sub_transid: {
    width: '100%',
    maxwidth: 230,
  },
  fetch_btn: {
    alignSelf: 'flex-end',
    marginTop: 'auto',
    margin: `auto 3px`,
  },
  table: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  table_header: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(1),
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    alignItems: 'center',
  },
  table_column: {
    width: '100%',
    paddingRight: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
  },
  table_cell: {
    fontSize: 15,
  },
  customer_cell: {
    width: 62,
    maxWidth: 62,
  },
  transId_cell: {
    width: 275,
    maxWidth: 275,
  },
  measure_cell: {
    width: 170,
    maxWidth: 170,
  },
  user_cell: {
    width: 150,
    maxWidth: 150,
  },
  update_cell: {
    width: 210,
    maxWidth: 210,
  },
  update_product_cell: {
    width: 250,
    maxWidth: 250,
  },
  update_NG_cell: {
    width: 80,
    maxWidth: 80,
  },
  hyper_link: {
    color: '#3f51b5',
  },
  table_column_selection: {
    width: 50,
  },
  table_column_action: {
    width: 60,
    marginLeft: 'auto',
    marginRight: 0,
    paddingRight: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  table_action_cell: {
    width: 28,
    minWidth: 'auto',
    height: 28,
    padding: 0,
    fontSize: 14,
  },
  table_selection: {
    marginLeft: -11,
    marginRight: 0,
  },
  table_content: {
    listStyle: 'none',
  },
  table_no_result_indicate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: `${theme.spacing(4)} 0 0 0`,
  },
  table_row: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  result_btn: {
    marginLeft: 'auto',
    marginTop: theme.spacing(3),
  },
  error: {
    marginBottom: theme.spacing(2.5),
  },
  record_header: {
    display: 'flex',
    width: '100%',
    padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
  },
  record_content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 0,
    margin: 0,
  },
  record_row: {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
  },
  record_column: {
    minWidth: 90,
    marginRight: theme.spacing(2),
  },
  config_container: {
    marginBottom: theme.spacing(5),
  },
  note_input: {
    width: `calc(100% - 150px)`,
    marginRight: 'auto',
  },
}));

export default useStyles;
