/* eslint-disable react/forbid-prop-types */
import React from 'react';
// import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';
import useStyle from './useStyles';

const QCHistoryResultRow = ({ row, handleDetailClick }) => {
  const { classes } = useStyle();
  return (
    <div className={classes.table_row}>
      <div className={`${classes.table_column} ${classes.transId_cell}`}>
        <Typography variant="body1" className={classes.table_cell}>
          {row.transId}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.measure_cell}`}>
        <Typography variant="body1" className={classes.table_cell}>
          {row.measureStyle}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.user_cell}`}>
        <Typography variant="body1" className={classes.table_cell} sx={{ wordBreak: 'break-all' }}>
          {row.measureUser}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.update_cell}`}>
        <Typography variant="body1" className={classes.table_cell}>
          {row.updateTime}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.update_NG_cell}`}>
        <Typography
          variant="body1"
          className={`${classes.table_cell} ${row.isNGHistoryData && classes.hyper_link}`}
        >
          {row.isNGHistoryData ? '是' : '否'}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.table_column_action}`}>
        <Button
          variant="outlined"
          color="primary"
          rounded
          customClasses={classes.table_action_cell}
          onClick={() => handleDetailClick(row)}
        >
          <SearchRoundedIcon fontSize="small" />
        </Button>
      </div>
    </div>
  );
};

QCHistoryResultRow.propTypes = {
  row: PropTypes.object.isRequired,
  handleDetailClick: PropTypes.func.isRequired,
};

export default QCHistoryResultRow;
