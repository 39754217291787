export const dialogActions = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
};

const dialogReducer = (state, action) => {
  switch (action.type) {
    case dialogActions.OPEN: {
      const {
        title,
        message,
        body,
        cancelText,
        confirmText,
        singleBtn,
        noBtn,
        handleConfirm,
        handleCancel,
        disableBackdropClick,
      } = action.config;
      return {
        ...state,
        open: true,
        title,
        message,
        cancelText,
        confirmText,
        body: body || null,
        singleBtn: singleBtn || false,
        noBtn: noBtn || false,
        handleConfirm: handleConfirm || null,
        handleCancel: handleCancel || null,
        disableBackdropClick: disableBackdropClick || false,
      };
    }
    case dialogActions.CLOSE:
      return {
        ...state,
        singleBtn: false,
        noBtn: false,
        open: false,
        handleConfirm: null,
        disableBackdropClick: false,
      };
    default:
      return Error('Should not be here!!');
  }
};

export default dialogReducer;
