/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from 'react';
import Modal from '../components/Modal/Modal';
import dialogReducer, { dialogActions } from '../reducers/dialogReducer';

export const DialogContext = createContext();

const initialConfig = {
  open: false,
  title: '',
  message: '',
  body: '',
  cancelText: '',
  confirmText: '',
  singleBtn: false,
  noBtn: false,
  handleConfirm: null,
  handleCancel: null,
  disableBackdropClick: false,
};

const DialogContextProvider = ({ children }) => {
  const [config, dialogDispatch] = useReducer(dialogReducer, initialConfig);
  const [infoConfig, infoDialogDispatch] = useReducer(dialogReducer, initialConfig);
  const handleClose = () => {
    dialogDispatch({
      type: dialogActions.CLOSE,
      config: { ...initialConfig },
    });
  };
  const handleInfoClose = () => {
    infoDialogDispatch({
      type: dialogActions.CLOSE,
      config: { ...initialConfig },
    });
  };
  return (
    <DialogContext.Provider value={{ dialogDispatch, infoDialogDispatch }}>
      {children}
      <Modal handleClose={handleClose} {...config} />
      <Modal handleClose={handleInfoClose} {...infoConfig} />
    </DialogContext.Provider>
  );
};

export default DialogContextProvider;
