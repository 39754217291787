import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import useCommonStyles from '../useStyles';
import useStyle from './useStyles';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import DetailDialog from '../QCExport/DetailDialog';
import useAxios from '../../../hooks/useAxios';
import useCancelToken from '../../../hooks/useCancelToken';
import CircularProgress from '../../../components/CircularProgress/CircularProgress';
import QCHistoryResultRow from './QCHistoryResultRow';
import regex from '../../../constants/validationRegex';
import { sinteringSizeType, qcExportMeasureStyle } from '../../../constants/enums';

const QCHistory = ({ transId, workOrder, isValidNGTrans }) => {
  const { classes } = useStyle();
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();
  const [results, setResults] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [currentRow, setCurrentRow] = useState({});

  const handleDetailClose = () => {
    setOpenDetail(false);
    setCurrentRow({});
  };

  const handleDetailClick = (row) => {
    setOpenDetail(true);
    setCurrentRow(row);
  };

  const handleGetQCHistory = async () => {
    setIsLoading(true);
    const styleId = regex.FORMING_TRANS_ID.test(transId) ? 0 : 1;
    const sizeTypeList = regex.SINTERING_TRANS_ID.test(transId)
      ? [sinteringSizeType.SIZE, sinteringSizeType.INSPECTION]
      : undefined;
    const cancelToken = newCancelToken();
    const [status, result, rtCode] = await useAxios(
      '/getQCHistory',
      { transId, scOdno: workOrder, styleId, sizeTypeList },
      true,
      cancelToken
    );
    setIsLoading(false);
    if (status) {
      setResults(
        result.transfer.map((row, idx) => {
          let measureStyleId = row.styleId === 0 ? qcExportMeasureStyle.FORMING : 0;
          if (row.sizeType && row.sizeType === 1)
            measureStyleId = qcExportMeasureStyle.SINTERING_INSPECTION;
          if (row.sizeType && row.sizeType === 2)
            measureStyleId = qcExportMeasureStyle.SIZE_ELECTRICAL;
          return {
            measureStyleId,
            rowId: `qcHistory_${idx + 1}`,
            checkId: row.checkId, // For detail api call
            styleId: row.styleId, // For detail api call
            transId: row.transId,
            customer: row.customerAlias,
            machine: row.machineName,
            product: row.prodName,
            measureStyle: row.styleName,
            measureUser: row.modifyUserName,
            updateTime: format(new Date(row.modifyDate), 'yyyy-MM-dd HH:mm:ss'),
            isChecked: false,
            isNGHistoryData: row.isNGHistoryData,
          };
        })
      );
      setShowResult(true);
    } else if (rtCode === 'E22') {
      setResults([]);
      setShowResult(true);
    }
  };

  useEffect(() => {
    if (!isValidNGTrans) return;
    handleGetQCHistory();
  }, [transId, isValidNGTrans]);

  return (
    <>
      {showResult && !isLoading && (
        <PaperContainer className={commonClasses.container}>
          <Typography variant="h6">量測歷史紀錄</Typography>
          <Grid container className={commonClasses.content}>
            <div className={classes.table}>
              <div className={classes.table_header}>
                <div className={`${classes.table_column} ${classes.transId_cell}`}>
                  <Typography variant="subtitle2">移轉單號</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.measure_cell}`}>
                  <Typography variant="subtitle2">量測型態</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.user_cell}`}>
                  <Typography variant="subtitle2">量測人員</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.update_cell}`}>
                  <Typography variant="subtitle2">更新時間</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.update_NG_cell}`}>
                  <Typography variant="subtitle2">是否NG</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.table_column_action}`}>
                  <Typography variant="subtitle2">Detail</Typography>
                </div>
              </div>
              <div className={classes.table_content}>
                {results.length === 0 && (
                  <div className={classes.table_no_result_indicate}>
                    <Typography variant="body1">查無相關資料</Typography>
                  </div>
                )}
                {results.map((row) => (
                  <QCHistoryResultRow
                    row={row}
                    key={row.rowId}
                    handleDetailClick={handleDetailClick}
                  />
                ))}
              </div>
            </div>
          </Grid>
        </PaperContainer>
      )}{' '}
      {!showResult && isLoading && (
        <CircularProgress alignItems="center" style={{ height: '200px' }} />
      )}
      <DetailDialog
        open={openDetail}
        handleDetailClose={handleDetailClose}
        currentRow={currentRow}
      />
    </>
  );
};

QCHistory.propTypes = {
  transId: PropTypes.string.isRequired,
  workOrder: PropTypes.string.isRequired,
  isValidNGTrans: PropTypes.bool.isRequired,
};

export default QCHistory;
