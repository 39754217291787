/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useReactPath from '../hooks/useReactPath';
import { AuthContext } from '../contexts/AuthContext';
import { authActions } from '../reducers/authReducer';
import useLoginAxios from '../hooks/useLoginAxios';
import useLineAxios from '../hooks/useLineAxios';
import { DialogContext } from '../contexts/DialogContext';
import { dialogActions } from '../reducers/dialogReducer';
import useCancelToken from '../hooks/useCancelToken';

const withLoginCheck = (Component) => (props) => {
  const { authDispatch } = useContext(AuthContext);
  const { dialogDispatch } = useContext(DialogContext);
  const [isLoginPending, setIsLoginPending] = useState(false);
  const navigate = useNavigate();
  const path = useReactPath();
  const lineParam = useRef({});
  const lineToken = useRef({});
  const lineLogin = useRef({});
  const { newCancelToken } = useCancelToken();

  const copyUserId = (userId) => {
    const errorDialog = {
      type: dialogActions.OPEN,
      config: {
        title: '無法複製',
        message: `無法自動複製ID，請自行複製括號中的ID 【${userId}】`,
        singleBtn: true,
        handleConfirm: () => {
          window.location.href = `${process.env.REACT_APP_BASE_URL}/#/login`;
        },
      },
    };
    if (navigator.clipboard) {
      navigator.clipboard.writeText(userId).then(
        () => {
          window.location.href = `${process.env.REACT_APP_BASE_URL}/#/login`;
        },
        () => {
          dialogDispatch(errorDialog);
        }
      );
    }
    dialogDispatch(errorDialog);
  };

  const doSysLogin = async () => {
    const { email } = lineLogin.current;
    const { accessToken } = lineToken.current;
    const request = {
      userLine: email,
      token: accessToken,
    };
    const prejwttoken = localStorage.getItem('pre-jwttoken');
    const cancelToken = newCancelToken();
    const [status, result, jwttoken] = await useLoginAxios(
      '/userLogin',
      request,
      false,
      cancelToken,
      prejwttoken
    );
    if (status) {
      const { privilege, userId, needId } = result;
      if (needId) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '您尚無此系統之權限',
            message: `您尚無此系統之權限，請將以下ID交由主管人員進行權限設定，設定完成後始可登入系統: ${needId}`,
            singleBtn: true,
            confirmText: '複製',
            handleConfirm: () => copyUserId(needId),
          },
        });
        return;
      }
      privilege.push({
        titleId: 0,
        titleName: 'Home',
        privilegeList: [
          { privilegeId: 0, titleId: 0, linkUrl: '/', privilegeName: 'Home', loginType: 1 },
        ],
      });
      lineLogin.current.userId = userId;
      localStorage.setItem('privilege', JSON.stringify(privilege));
      localStorage.setItem('jwttoken', jwttoken);
      localStorage.setItem('currentUser', JSON.stringify(lineLogin.current));
      authDispatch({
        type: authActions.LOGIN,
      });
      const targetPath = localStorage.getItem('targetPath') || '/';
      setIsLoginPending(false);
      window.location.href = `${process.env.REACT_APP_BASE_URL}/#${targetPath}`;
    } else {
      setIsLoginPending(false);
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: 'Oops',
          message: result,
          singleBtn: true,
          handleConfirm: () => navigate('/login', { replace: true }),
        },
      });
    }
  };

  const getUserProfile = async () => {
    const { id } = lineParam.current;
    const { idToken } = lineToken.current;
    const params = new URLSearchParams();
    params.append('client_id', id);
    params.append('id_token', idToken);
    const [status, result] = await useLineAxios('/verify', params);
    if (status) {
      const { name, email, picture } = result;
      lineLogin.current = {
        name,
        email,
        picture,
      };
      doSysLogin();
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: 'Oops',
          message: 'Line登入失敗，請稍後再試一次',
          singleBtn: true,
          handleConfirm: () => navigate('/login', { replace: true }),
        },
      });
    }
  };

  const getLineToken = async (code) => {
    const { id, secret } = lineParam.current;
    const url = `${process.env.REACT_APP_BASE_URL}/`;
    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('code', code);
    params.append('redirect_uri', url);
    params.append('client_id', id);
    params.append('client_secret', secret);
    const [status, result] = await useLineAxios('/token', params);
    const { access_token, id_token } = result;
    localStorage.setItem(`access_token`, access_token);
    if (status) {
      lineToken.current = {
        accessToken: access_token,
        idToken: id_token,
      };
      getUserProfile();
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: 'Oops',
          message: 'Line登入失敗，請稍後再試一次',
          singleBtn: true,
          handleConfirm: () => navigate('/login', { replace: true }),
        },
      });
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    setIsLoginPending(false);

    if (currentUrl.includes('?') && currentUrl.includes('state=')) {
      // Means that it's coming from Line Login callback
      setIsLoginPending(true);
      // e.g.: http://localhost:3000/?code=ysjzqkJp4p4fI4Z1eofI&state=f2469a7766ef4c7e9cf488042d1da0db#/
      const searchStr = currentUrl.split('?')[1].split('#')[0];
      const params = {};
      searchStr.split('&').forEach((item) => {
        const [key, value] = item.split('=');
        params[key] = value;
      });
      const { code } = params;
      lineParam.current = JSON.parse(localStorage.getItem('lineParam'));
      if (code) {
        getLineToken(code);
      } else {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: 'Oops',
            message: 'Line登入失敗，請稍後再試一次',
            singleBtn: true,
            handleConfirm: () => navigate('/login', { replace: true }),
          },
        });
      }
    }
    if (
      currentUrl.includes('?') &&
      currentUrl.includes('devLogin') &&
      process.env.REACT_APP_PROJECT_PHASE === 'DEVELOPMENT'
    ) {
      lineToken.current = {
        accessToken:
          'eyJhbGciOiJIUzI1NiJ9.eAmeOi1umKSY9rgFMTdV6VmAps_4HL0k3vRtSUYPhwGAaC9LN5kGA-0bwta-svWjSRxv_oS4eIGpeVajWlV8DBWCCEbpt7sH0MjlKLdHW0vLV0F6CqUVl8Dlwrs75Eh2nNCMTblibVxaVTZSLHmcbh6XGj8KaYkU6Vdn_Wpi1UQ.juJqJ3w7tzVldFWrpDrh6y0wR0t-DpW6IE3JgvxLlzI',
      };
      lineLogin.current = {
        ...lineLogin.current,
        email: 'jgdev@jgallop.com',
      };
      doSysLogin();
    }
  }, [path]);

  return <Component isLoginPending={isLoginPending} {...props} />;
};

export default withLoginCheck;
